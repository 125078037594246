@import "../../assets/sass/var";

.radioTerminow {
    margin-bottom: 50px;
    position: relative;
    //height: 50px;
    height: 40px;
    &_small {
        .radioTerminow{
            &__input {
                width: 20px;
                height: 20px;
                //background-color: aqua;
                left: 16px;
                cursor: pointer;
            }

            &__label {
                font-size: 18px;
                margin-left: 40px;
                //white-space: pre-wrap;
                cursor: pointer;
            }
            
            &__label:before {
                content: "";
                display: block;
                width:29px;
                height: 29px;
                background-color: $background-label-before;
                border-radius: 32px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                cursor: pointer;
            }
        
            &__label:after {
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                border-radius: 16px;
                background: url("../../assets/img/radio.svg") no-repeat;
                background-position: center;
                opacity: 0;
                position: absolute;
                top: 7px;
                left: 7px;
                z-index: 2;
                cursor: pointer;
            }

            &__d {
                margin-left: 40px;
                margin-top: -25px;
            }
        } 
    }

    &__input {
        appearance: none;
        position: absolute;
        width: 25px;
        height: 25px;
        z-index: 100;
    }
    &__input:hover {
        cursor: pointer;
    }

    &__label {
        font-size: 18px;
        margin-left: 40px;
    }
    &__label:hover {
        cursor: pointer;
    }

    &__d {
        margin-left: 40px;
        margin-top: -20px;
    }

    &__label:before {
        content: "";
        display: block;
        width: 32px;
        height: 32px;
        background-color: $background-label-before;
        border-radius: 32px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    &__label:after {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        background: url("../../assets/img/radio.svg") no-repeat;
        background-position: center;
        opacity: 0;
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 2;
    }

    &__input:checked + &__label:before {
        background-color: $day-color;
    }

    &__input:checked + &__label:after {
        opacity: 1;
    }

    &__input:not(:disabled):checked + &__label::after {
        box-shadow: 0 0 0 0 $background-checked;
    }
    &__input:not(:disabled):checked + &__label::after {
        box-shadow: 0 0 0 0 $background-checked;
    }

    &__input:not(:disabled):checked + &__label::before {
        box-shadow: 0 0 0 0;
    }

    &__input:disabled:checked + &__label::before {
        background-color: $background-checked;
    }

    &__input:not(:disabled):not(:checked) + &__label::before {
        box-shadow: 0 0 0 0 $background-not-disabled;
    }
    
    &__input:disabled:not(:checked) + &__label::before {
        background-color: $background-disabled;
    }
}
