.preloader {
//margin-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

z-index: 1600;
    &-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        mix-blend-mode: multiply; 
    }
}