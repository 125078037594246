@import "../../assets/sass/var";

//* {
//  box-sizing: border-box;
//}
.fieldTerminow {
  box-sizing: border-box;
}

.fieldTerminow {
  //height: 105px;
  margin-bottom: 40px;
  position: relative;

  &_focus {
    .fieldTerminow {
      &__label {
        top: 0px;
        position: absolute;
        transition: 0s;
        font-size: $label-font-size;
        letter-spacing: 0.4px;
      }
    }
  }

  &_unfocus {
    .fieldTerminow {
      &__label {
        transition: 0s;
      }
    }
  }

  &_blur {
    .fieldTerminow {
      &__label {
        font-size: 16px;
      }
    }
  }

  &_unblur {
    .fieldTerminow {
      &__label {
      }
    }
  }

  &_focus {
    .fieldTerminow {
      &__labels {
        top: 0px;
        position: absolute;
        transition: 0s;
        font-size: $label-font-size;
        letter-spacing: 0.4px;
      }
    }
  }

  &_unfocus {
    .fieldTerminow {
      &__labels {
        transition: 0s;
      }
    }
  }

  &_blur {
    .fieldTerminow {
      &__labels {
        font-size: 16px;
      }
    }
  }

  &_unblur {
    .fieldTerminow {
      &__labels {
      }
    }
  }

  &__input {
    height: 64px;
    width: 100%;
    //min-width: 280px;
    //min-width: 340px;
    //min-width: 100%;
    min-width: 93.5%;
    max-width: 100%;
    border: 2px solid #D6D8E7;
    letter-spacing: 0.75px;
    border-radius: 16px;
    background-color: $btn-text-color;
    font-size: $input-text-font-size;
    color: $btn-background;
    padding: 21px 24px;
    padding-bottom: 0px;
    box-sizing: border-box;

    &::-webkit-calendar-picker-indicator {
      //font-size: 10px;
      display: none;
    }
  }


  &__label {
    position: absolute;
    top: 12px;
    left: 18px;
    margin: 8px;
    letter-spacing: 0.75px;
    transition: 0s;
    font-size: 16px;
    color: $paragraph-text-background;
  }

  &__imgNone {
    display: none !important;

  }

  &__img {
    position: absolute;
    float: right;
    right: 10px;
    top: 10px;
    padding: 10px;

  }

  .react-calendar {
    min-height: 100% !important;
    padding: 8px !important;

    &__year-view {
      &__months {

        &__month {
          overflow: visible;
          width: 60px !important;
          min-width: 68px !important;
          max-width: 60px !important;
          margin: 5px 5px !important;
        }
      }

    }

    &__century-view {
      &__decades {

        &__decade {
          overflow: visible;
          width: 60px !important;
          min-width: 40px !important;
          max-width: 60px !important;
          margin: 5px 5px !important;
        }
      }

    }


    &__decade-view {
      &__years {
        justify-content: center;

        &__year {
          overflow: visible !important;
        }
      }
    }

    &__tile {
      &--hasActive {
        background: #49CD6E !important;
        color: white !important;
        padding: 5px !important;
      }
    }

    margin-top: 10px;
    right: 0;
    background-color: white;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    position: absolute;
    z-index: 100 !important;
    width: 320px !important;

    &__navigation {
      height: 20px !important;

      &__label:hover {
        background-color: transparent !important;
      }

      &__label:focus {
        background-color: transparent !important;
      }

      &__label {
        background-color: transparent !important;

        &__labelText {
          font-size: 14px !important;
          @media (min-width: 1030px) and (max-width: 1150px) {
            font-size: 12px !important;
          }
        }
      }

      &__arrow {
        background-color: transparent !important;
      }

      &__arrow:focus {
        background-color: transparent !important;
      }

      &__arrow:hover {
        background-color: transparent !important;
      }

      &__prev-buttom {
        background-color: transparent !important;

      }

      &__prev-buttom:hover {
        background-color: transparent !important;

      }

      &__prev-buttom:focus {
        background-color: transparent !important;

      }
    }


    abbr {
      font-size: 13px !important;
      @media (min-width: 1023px) and (max-width: 1100px) {
        font-size: 10px !important;
      }
    }

    button {
      font-size: 14px !important;
      margin-bottom: 0px !important;
      padding: 5px !important;
      padding-left: 5px;
      border-radius: 0px;

      @media (min-width: 360px) and (max-width: 799px) {
        font-size: 14px !important;
        margin-bottom: 0px !important;
        padding: 5px 2px 5px 2px !important;
        //padding-left: 5px;
        border-radius: 0px;
        width: 23px !important;
        min-width: 23px !important;
        max-width: 100% !important;
      }
    }

    &__month-view {
      &__weekdays {
        margin: 0px !important;

        &__weekday {
          padding: 2px !important;
          margin: 0 !important;
          //width: 0;
          //min-width: 0;
          //max-width: 10;

          @media (min-width: 200px) and (max-width: 359px) {
            width: 32px !important;
            min-width: 32px !important;
            max-width: 38px !important;
          }

          @media (min-width: 1024px) and (max-width: 1100px) {
            width: 32px !important;
            min-width: 28px !important;
            max-width: 38px !important;
          }
        }
      }

      &__days {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;

        &__day {
          width: 31px !important;
          //min-width: 24px !important;
          //min-width: 21px !important;
          //min-width: 35px !important;
          min-width: 31px !important;
          max-width: 38px !important;
          //margin-top: 8px !important;
          margin: 8px 2px 0 2px !important;

          @media (min-width: 200px) and (max-width: 359px) {
            width: 30px !important;
            min-width: 26px !important;
            max-width: 38px !important;
            margin: 8px 4px 0 4px !important;
          }

          @media (min-width: 360px) and (max-width: 799px) {
            //width: 30px !important;
            //min-width: 26px !important;
            //max-width: 38px !important;
            //margin: 8px 6px 0 6px !important;

            width: 28px !important;
            min-width: 22px !important;
            max-width: 38px !important;
            margin: 8px 5px 0 5px !important;
          }

          @media (min-width: 800px) and (max-width: 1022px) {
            width: 20px !important;
            min-width: 30px !important;
            max-width: 38px !important;
            margin: 8px 6px 0 6px !important;
          }

          @media (min-width: 1023px) and (max-width: 1100px) {
            width: 20px !important;
            min-width: 26px !important;
            max-width: 38px !important;
            margin: 8px 4px 0 4px !important;
          }

          @media (min-width: 1101px) and (max-width: 1201px) {
            width: 20px !important;
            min-width: 28px !important;
            max-width: 38px !important;
            margin: 8px 4px 0 4px !important;
          }



        }
      }
    }
  }

  &__labels {
    position: absolute;
    top: 12px;
    left: 18px;
    margin: 8px;
    letter-spacing: 0.75px;
    transition: 0s;
    font-size: 16px;
    color: $paragraph-text-background;
  }

  &__select {
    min-height: 64px;
    width: 100%;
    border: 2px solid #D6D8E7;
    letter-spacing: 0.75px;
    border-radius: 16px;
    background-color: $btn-text-color;
    font-size: $input-text-font-size;
    color: $btn-background;
    padding: 21px 24px;
    padding-bottom: 0px;
  }

  &__option {
    margin-top: 10px;
  }

  &__clear :hover {
    opacity: 0.7;
  }

  &__clear {
    color: $btn-clear;
    position: absolute;
    top: 20px;
    right: 29px;
    z-index: 1;
  }

  &_order {
    .fieldTerminow {
      &__input {
        margin-bottom: 7px;
        position: relative;
        height: 28px;
        width: 100%;
        background-color: transparent;
        padding: 1px 1px;
        color: $btn-background;
        border: none;
        font-weight: normal;
        cursor: text;
        border-radius: 0px;
        text-align: center;
        font-size: 15px;
        box-sizing: border-box;
        outline: none;
      }

      &__input:hover {
        border: 1.5px solid $btn-background;
        background-color: $btn-text-color;
      }

      &__input:focus {
        border: 1.5px solid #2694da;
        background-color: $btn-text-color;
      }

      &__label {
        font-weight: lighter;
        margin: 0;
        display: flex;
        justify-content: center;
        font-size: 13px;
        position: relative;
        top: -30px;
        left: 0px;
      }

      &__labels {
        opacity: 0;
        font-weight: lighter;
        margin: 0;
        display: flex;
        justify-content: center;
        font-size: 13px;
        position: relative;
        top: -32px;
        left: 0;
      }
    }
  }

  &_active {
    .fieldTerminow {
      &__input:focus {
        border: 2px solid $btn-background;
        height: 64px;
        background-color: $btn-text-color;
      }

      &__input:hover {
        border: 2px solid $btn-background;
        height: 64px;
        background-color: $btn-text-color;
      }

      &__help-text {
        font-size: 14px;
        letter-spacing: $help-text-spacing;
        color: #737484;
      }
    }
  }

  &_date {
    .fieldTerminow {
      &__input::-webkit-calendar-picker-indicator {
        opacity: 0;
      }

      input::-webkit-calendar-picker-indicator {
        display: none;
      }

      &__input:hover {
        border: 2px solid $btn-background;
        height: 64px;
        background-color: $btn-text-color;
      }
    }
  }

  &_datetime {
    .fieldTerminow {
      &__input::-webkit-calendar-picker-indicator {
        opacity: 0;
      }

      input::-webkit-calendar-picker-indicator {
        display: none;
      }

      &__input:hover {
        border: 2px solid $btn-background;
        height: 64px;
        background-color: $btn-text-color;
      }
    }
  }

  &_time {
    .react-calendar {
      display: none;
    }

    .fieldTerminow {
      &__input::-webkit-calendar-picker-indicator {
        opacity: 0;
      }

      input::-webkit-calendar-picker-indicator {
        display: none;
      }

      &__input:hover {
        border: 2px solid $btn-background;
        height: 64px;
        background-color: $btn-text-color;
      }
    }
  }

  &_typing {
    .fieldTerminow {
      &__input:focus {
        border: 2px solid $btn-background;
        background-color: $btn-text-color;
      }

      &__input:hover {
        border: 2px solid $btn-background;
        background-color: $btn-text-color;
      }
    }
  }

  &_disabled {
    .fieldTerminow {
      &__input {
        background-color: $main-background;
      }
    }
  }

  &_caption {
    .fieldTerminow {
      &__input {
        margin-bottom: 9px;
      }

      &__help-text {
        font-size: $help-text-size;
        letter-spacing: $help-text-spacing;
        color: $paragraph-text-background;
      }

      &__clear {
        margin-top: -75px;
      }
    }
  }

  &_search {
    .fieldTerminow {
      &__input {
        padding: 24px;
        padding-bottom: 0px;
      }

      &__label {
        left: 18px;
        margin-top: 1px;
      }

      &__labels {
        left: 18px;
        margin-top: 1px;
      }
    }
  }

  &_user {
    .fieldTerminow {
      &__input {
        background-image: url("../../assets/img/profile.svg");
        background-repeat: no-repeat;
        background-position: 20px;
        background-color: transparent;
        padding: 25px 57px;
      }

      &__label {
        left: 50px;
      }
    }
  }

  &_success {
    .fieldTerminow {
      &__input {
        background-color: #f2fffb;
        border: 2px solid #00ba88;
      }

      &__help-text {
        font-size: $help-text-size;
        letter-spacing: $help-text-spacing;
        color: #00966d;
      }
    }
  }

  &_error {
    .fieldTerminow {
      &__input {
        background-color: $input-color-error;
        border: 2px solid $input-border-error;
      }

      &__label {
        color: $input-color-help-text;
      }

      &__clear {
        margin-top: -69px;
        color: $input-border-error;
      }

      &__help-text {
        letter-spacing: $help-text-spacing;
        font-size: $help-text-size;
        color: $input-color-help-text;
        padding-top: -20px;
      }
    }
  }
}

.order {
  margin-bottom: -27px;

  .react-calendar {
    display: none;
  }

  &_focus {
    .fieldTerminow {
      &__labels {
        top: 0px;
        position: absolute;
        transition: 0s;
        font-size: $label-font-size;
        letter-spacing: 0.4px;
      }
    }
  }

  &_unfocus {
    .fieldTerminow {
      &__labels {
        display: none;
        transition: 0s;
      }
    }
  }

  &_order {
    .order {
      &__input {
        cursor: text;
      }
    }
  }
}
