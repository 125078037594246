.react-calendar {
  abbr {
    content: none !important;
    text-decoration: none !important;
  }

  max-width: 100%;
  background: white;
  border: 0px solid #a0a096 !important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;

  width: 700px !important;
  min-height: 493px !important;

  &--doubleView {
    width: 700px;

    .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;

      > * {
        width: 50%;
        margin: 0.5em;
      }
    }
  }

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  button {
    margin: 0;
    border: 0;
    outline: none;

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;


    &__label {
      &__labelText {
        line-height: 26px !important;
        font-family: inherit !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        color: inherit !important;
        display: inline-block !important;
        margin-left: 0.5ch !important;
        padding: 0 !important;
      }
    }

    button {
      min-width: 44px;
      background: none;

      &:disabled {
        background-color: rgb(240, 240, 240);
      }

      &:enabled {
        &:hover,
        &:focus {
          background-color: rgb(230, 230, 230);
        }
      }
    }
  }

  &__month-view {
    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;


      &__weekday {
        padding: 0.5em;


        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        /* height: 88px !important; */
        margin: 20px 0 !important;
        letter-spacing: 0.25px !important;
        color: #2A2A30 !important;
        display: block !important;
      }
    }

    &__weekNumbers {
      .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px !important;
        font-weight: normal !important;
        color: #737484 !important;
      }
    }

    &__days {
      &__day {
        &--weekend {
          color: rgb(209, 0, 0);
        }

        &--neighboringMonth {
          color: rgb(117, 117, 117);
        }
      }
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      padding: 2em 0.5em;
    }
  }

  &__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;

    width: 100% !important;
    min-width: auto !important;

    border-radius: 10px !important;
    //width: 10.2857143% !important;
    flex-basis: 10.2857143% !important;
    margin: 10px 2% !important;
    font-size: 20px !important;
    font-weight: 500 !important;

    //max-width: 14.2857143% !important;

    &:disabled {
      background-color: rgb(240, 240, 240);
    }

    &:enabled {
      &:hover,
      &:focus {
        background-color: rgb(230, 230, 230);
      }
    }

    &--now {
      background: none !important;
      &:enabled {
        &:hover,
        &:focus {
        }
      }
    }

    &--hasActive {

      &:enabled {
        &:hover,
        &:focus {
        }
      }
    }

    &--active {
      background: #49CD6E !important;
      color: white;

      &:enabled {
        &:hover,
        &:focus {
        }
      }
    }
  }

  &--selectRange {
    .react-calendar__tile {
      &--hover {
        background-color: rgb(230, 230, 230);
      }
    }
  }
}
