@import "../../assets/sass/var.scss";

//* {
//  box-sizing: border-box;
//}
.area {
  box-sizing: border-box;
}

.area {
  margin-bottom: 40px;
  position: relative;
   &_focus {
    .area {
      &__label {
        top: 0px;
        position: absolute;
        transition: 0s;
        font-size: $label-font-size;
        color: $paragraph-text-background;
      }
    }
  } 

  &_focus {
    .area {
      &__label {
        top: 0px;
        position: absolute;
        transition: 0s;
        font-size: $label-font-size;
        letter-spacing: 0.4px;
      }
     
    }
  }

  &_unfocus {
    .area {
      &__label {
        transition: 0s;
      }
    }
  }

  &__text {
    resize: vertical;
    height: 165px;
    width: 100%;
    border: none;
    letter-spacing: 0.75px;
    border-radius: 16px;
    background-color: $btn-text-color;
    padding: 30px 30px;
    padding-bottom: 0px;
    border: 2px solid #D6D8E7;
    font-size: 14px;
    font-family: 'PoppinsRegular';
    letter-spacing: $help-text-spacing;
    color: $btn-background;
    box-sizing: border-box;
  }

  &__label {
    position: absolute;
    top: 20px;
    left: 24px;
    margin: 8px;
    letter-spacing: 0.75px;
    transition: 0s;
    font-size: 16px;
    color: $paragraph-text-background;
  }

  &__clear :hover {
    opacity: 0.7;
  }

  &__clear {
    color: $btn-clear;
    position: absolute; 
    top: 20px; 
    right: 29px;
    z-index: 1;
  }

  &_active {
    .area {
      &__text:focus {
        border: 2px solid $btn-background;
        background-color: $btn-text-color;
      }

      &__text:hover {
        border: 2px solid $btn-background;
        background-color: $btn-text-color;
      }

      &__help-text {
        font-size: 14px;
        letter-spacing: $help-text-spacing;
        color: #737484;
      }
    }
  }

  &_typing {
    .area {
      &__text:focus {
        border: 2px solid $btn-background;
        background-color: $btn-text-color;
      }

      &__text:hover {
        border: 2px solid $btn-background;
        background-color: $btn-text-color;
      }
    }
  }

  &_disabled {
    .area {
      &__text {
        background-color: $main-background;
      }
    }
  }

  &_caption {
    .area {
      &__text {
        margin-bottom: 9px;
      }

      &__help-text {
        font-size: $help-text-size;
        letter-spacing: $help-text-spacing;
        color: $paragraph-text-background;
      }

      &__clear {
        margin-top: -75px;
      }
    }
  }

  &_success {
    .area {
      &__text {
        background-color: #f2fffb;
        border: 2px solid #00ba88;
      }

      &__help-text {
        font-size: $help-text-size;
        letter-spacing: $help-text-spacing;
        color: #00966d;
      }
    }
  }

  &_error {
    .area {
      &__text {
        background-color: $input-color-error;
        border: 2px solid $input-border-error;
      }

      &__label {
        color: $input-color-help-text;
      }

      &__clear {
        margin-top: -69px;
        color: $input-border-error;
      }

      &__help-text {
        letter-spacing: $help-text-spacing;
        font-size: $help-text-size;
        color: $input-color-help-text;
        padding-top: -20px;
      }
    }
  }
}
