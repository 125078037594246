$btn-background: #2a2a30;
$btn-text-color: #ffffff;
$main-background: #f6f6f8;
$main-success-background: #e5e5e5;
$paragraph-text-background: #737484;
$day-color: #49cd6e;
$border-btn-color: #d6d8e7;
$background-even: #f2f2f5;
$background-success: #e1e1e1;
$input-color-error: #fff3f8;
$input-border-error: #ed2e7e;
$input-color-help-text: #c30052;
$btn-clear: #14142B;
$background-border-table: #E6E6E6;
$input-background: #f6f6f8;
$input-text-color: #737484;
$input-background-focus: #ffffff;
$label-font-size: 14px;
$input-text-font-size: 16px;
$help-text-size: 14px;
$general-error-text-size: 16px;
$help-text-spacing: 0.25px;
$background-checked: #49cd6e71;
$background-label-before: #d6d8e7;
$background-disabled: #d6d8e793;
$background-not-disabled: #dfdddd5d;
$btn-color-gray: #eff0f6;
$btn-hover-blue: #2694da;
$btn-border: #D6D8E7;
$text-weekend-color: #ae1412;
$border-table: #ddd;








