@import "../../assets/sass/var";

.radioTerminow {
  margin-bottom: 50px;
  position: relative;
  height: 40px;

  &_small {
    .radioTerminow {
      &__input {
        width: 20px;
        height: 20px;
        left: 16px;
        cursor: pointer;
      }

      &__labelPhoto {
        font-size: 18px;
        margin-left: 40px;
        cursor: pointer;

        .photo {
          width: 50px;
          height: 50px;
          background: #ae1412;
          z-index: 1000;
        }
      }

      &__labelPhoto:after {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 14px;
        background: url("../../assets/img/radio.svg") no-repeat;
        background-position: center;
        opacity: 0;
        position: absolute;
        top: 7px;
        left: 7px;
        z-index: 2;
        cursor: pointer;
      }

      &__d {
        margin-left: 40px;
        margin-top: -25px;
      }
    }
  }

  &__input {
    appearance: none;
    position: absolute;
    width: 25px;
    height: 25px;
    z-index: 100;
  }

  &__input:hover {
    cursor: pointer;
  }

  &__labelPhoto {
    font-size: 18px;
    margin-left: 40px;

    .photo {
      display: block;
      width: 26px;
      height: 26px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 26px;
      position: absolute;
      top: 3px;
      left: 3px;
      z-index: 1;
    }
  }

  &__labelPhoto:hover {
    cursor: pointer;
  }

  &__d {
    margin-left: 42px;
    margin-top: -21px;
  }

  &__labelPhoto:before {
    content: "";
    display: block;
    width: 26px;
    height: 26px;
    border: 3px solid $background-label-before;
    border-radius: 26px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__labelPhoto:after {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background: url("../../assets/img/Checkbox.svg") no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 22px;
    z-index: 2;
  }

  &__input:checked + &__labelPhoto:before {
    width: 26px;
    height: 26px;
    border-radius: 26px;
    border: 3px solid $day-color;
  }

  &__input:checked + &__labelPhoto:after {
    opacity: 1;
  }

  &__input:not(:disabled):checked + &__labelPhoto::after {
    box-shadow: 0 0 0 0 $background-checked;
  }

  &__input:not(:disabled):checked + &__labelPhoto::after {
    box-shadow: 0 0 0 0 $background-checked;
  }

  &__input:not(:disabled):checked + &__labelPhoto::before {
    box-shadow: 0 0 0 0;
  }

  &__input:disabled:checked + &__labelPhoto::before {
    background-color: $background-checked;
  }

  &__input:not(:disabled):not(:checked) + &__labelPhoto::before {
    box-shadow: 0 0 0 0 $background-not-disabled;
  }

  &__input:disabled:not(:checked) + &__labelPhoto::before {
    background-color: $background-disabled;
  }
}
