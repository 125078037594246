@import "../../../assets/sass/var";

//* {
//  box-sizing: border-box;
//}
.online-dates {
  box-sizing: border-box;
}

.online-dates {
  padding: 30px 0;
  background: transparent;
  margin: auto;
  margin-bottom: -30px;
  display: inline;

  form {
    text-align: initial !important;
  }

  .header {
    display: flex;
    justify-content: center;
    margin-left: -700px;

    &__info {
      margin: 40px 0px 20px 120px;

      &-name1 {
        font-size: 14px;
        font-weight: bolder;
        color: $paragraph-text-background;
        line-height: 32px;
        letter-spacing: 3px;
      }

      &-name2 {
        font-size: 64px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 69px;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 400px;
    max-width: 1200px;
    margin: 20px auto;
    //border: 1px solid #737484;
    //padding: 5px;

    .btn-back {
      width: 100%;
      height: 50px;
      margin-bottom: 20px;
    }

    .btn-back:hover {
      cursor: pointer;
    }

    .forms {
      order: 1;
      padding-bottom: 20px;
      background-color: white;
      border: 1px solid #E7E6E6;
      border-radius: 20px;
      padding-top: 20px;
      margin: 0 20px 20px 20px;
      flex: 1 2 550px;

      @media (min-width: 1023px) and (max-width: 1200px) {
        margin: 0 5px 20px 20px;
      }

      &__info {
        font-weight: bold;
        margin-left: 20px;
        font-size: 18px;
        letter-spacing: 0.3px;
      }

      &__number {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 55px 1fr;
        grid-column-gap: 24px;
        margin-top: 20px;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.3px;
      }

      &__comment {
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.3px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 55px 1fr;
        grid-column-gap: 24px;
        margin-top: 20px;
      }

      &__personal {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 24px;
        margin: 28px 20px 24px 20px;
        //min-width: 320px;
        min-width: 280px;
      }

      &__contacts {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 24px;
        margin: 28px 20px 24px 20px;
        min-width: 280px;
      }

      .number {
        margin-top: -52px;
      }

      .comment {
        margin-top: -50px;
      }

      .agreement {
        display: flex;
        margin: -30px 20px 0 20px;
        min-width: 280px;

        .privacy-policy {
          width: 100%;
          margin: 0 0 10px 0;
          user-select: none;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #737484;

          .check {
            height: 20px;
            float: left;
            margin: 0 10px 5px 0;
          }

          .link-privacy-policy {
            color: #007bff;
            text-decoration: none;
            background-color: transparent;
          }

          .link-privacy-policy:hover {
            color: #0056b3;
            text-decoration: underline;
          }
        }
      }

      .btn {
        margin-top: 20px;
        padding-bottom: 10px;
      }

      .general-error {
        letter-spacing: $help-text-spacing;
        font-size: $general-error-text-size;
        color: $input-color-help-text;
        text-align: center;
      }
    }

    .doctor {
      order: 2;
      border-radius: 20px;
      background-color: rgb(255, 255, 255);
      border: 1px solid #E7E6E6;
      margin: 0 20px 150px 20px;
      flex: 1 360px;
      min-height: 216px;
      max-height: 246px;

      @media (min-width: 1023px) and (max-width: 1200px) {
        margin: 0 20px 150px 5px;
      }

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &__text {
        position: relative;
        font-size: 14px;
        color: $paragraph-text-background;
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 20px;
        letter-spacing: 0.25px;
        font-weight: 400;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;

        &-photo {
          margin-right: 20px;
          float: right;
          overflow: hidden;
          width: 80px;
          height: 80px;
          background-size: 80px 80px;
          box-sizing: border-box;
          border-radius: 100%;
          display: block;
          position: relative;
        }

        &-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;

          &-titleBranch {
            margin: 0;
          }

          &-name {
            //font-size: 20px;
            color: $btn-background;
            letter-spacing: 0.3px;
            font-weight: bolder;
            margin-bottom: 0;
            padding: 5px 0;
          }

          &-location {
            font-size: 14px;
            letter-spacing: 0.25px;
            color: $paragraph-text-background;
            //margin-top: 4px;
          }
        }

        //&-name {
        //  font-size: 20px;
        //  color: $btn-background;
        //  letter-spacing: 0.3px;
        //  font-weight: bolder;
        //}

        //&-location {
        //  font-size: 14px;
        //  letter-spacing: 0.25px;
        //  color: $paragraph-text-background;
        //  margin-top: 4px;
        //}
      }

      &__date {
        min-width: 331px;
        max-width: 100%;
        min-height: 64px;
        margin: 24px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        padding: 20px;
        background: $btn-text-color;
        border: 2px solid $btn-color-gray;
        box-sizing: border-box;
        border-radius: 12px;

        &-img {
          width: 22px;
          height: 22px;
          mix-blend-mode: multiply;
        }

        &-value {
          height: 24px;
          font-size: 16px;
          line-height: 30px;
          letter-spacing: 0.25px;
          color: $btn-background;
          flex: none;
          order: 1;
          flex-grow: 0;
          margin-left: 13px;
        }

        &-day {
          font-weight: bold;
          color: #2A2A30;
        }
      }
    }

    .items {
      margin-top: 50px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__img {
        height: 50px;
        width: 50px;
      }

      &__text {
        position: relative;
        font-size: 14px;
        color: #737484;
        padding-left: 46px;
        letter-spacing: 0.25px;
        font-weight: 400;
        width: 350px;
      }

      &__text::before {
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        left: 0;
        top: -5px;
        background: url(../../../assets/img/item.svg) center 0 no-repeat;
      }
    }
  }

  .button {
    margin-left: 20px;
  }
}

@media screen and (max-width: 799px) {
  .online-dates {
    width: 100%;
    min-width: 500px;
    max-width: 100%;

    .area {
      width: 100%;
    }

    .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 20px;
      //min-width: 100%;
      //min-width: 335px;
      min-width: 316px;
      max-width: 100%;
      //max-width: 335px;

      .btn-back {
        order: 1
      }

      .forms {
        order: 3;
        min-width: 100%;
        max-width: 100%;

        &__personal {
          display: grid;
          grid-template-columns: 1fr;
          grid-column-gap: 24px;
          margin: 24px 15px;
        }

        .number {
          width: 100%;
          margin-top: -65px;
          margin-bottom: 20px;
        }

        .comment {
          margin-top: -50px;
        }

        &__info {
          margin-left: 26px;
        }

        .agreement {
          margin: 20px;
          margin-top: -40px;

          .privacy-policy {
            width: 100%;
            margin: 0;

            .check {
              height: 20px;
              float: left;
              margin: 0 10px 5px 0;
            }
          }
        }

        &__contacts {
          display: grid;
          grid-template-columns: 1fr;
          grid-column-gap: 24px;
          margin: 28px 15px;
        }

        &__number {
          display: grid;
          grid-template-columns: 1fr;
          grid-column-gap: 24px;
        }

        &__comment {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 50px 1fr;
          grid-column-gap: 24px;
        }
      }

      .doctor {
        order: 2;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 216px;
        max-height: 446px;
        margin: 0 0 24px 0;

        &__info {
          width: 100%;
          margin: 0 15px;
        }

        &__text {
          padding: 24px 0 0 0;
          @media (min-width: 200px) and (max-width: 400px) {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 24px 0 0 0;

            &-photo {
              margin-bottom: 20px;
              margin-right: 0;
            }

            &-wrapper {
              display: inline-flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              &-name {
                padding: 5px 0;
              }

              &-location {

              }
            }
          }
        }

        &__date {
          display: inline-flex;
          width: 100%;
          padding: 15px;
          min-width: 100%;
          max-width: 100%;

          &-value {
            font-size: 14px;
          }
        }

        .items {
          display: none;
        }
      }

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;

        .button {
          @media (min-width: 200px) and (max-width: 500px) {
            margin-left: 0px;
          }
        }
      }
    }

    .button {
      //padding: 16px 109px;
      padding: 16px 90px;
      //margin-left: 20px;

      //@media (min-width: 200px) and (max-width: 500px) {
      //  //margin-left: 16px;
      //}
    }
  }
}