@import "src/assets/sass/var";

.main {
    //background-color: $main-background;
    //width: 100%;
    //height: 100vh;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px auto;
    min-width: 400px;
    max-width: 850px;
    //border: 1px solid #737484;
    padding: 5px;

    //position: fixed;
    //top: 0;
    //left: 0;
    //display: inline-flex;
    //align-items: center;
    //align-content: center;
    //justify-content: center;
    //overflow: auto;
    //z-index: 2;
    &__content {
       //max-width: 600px;
        &-img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            mix-blend-mode: multiply;
           
        }

        &-title {
            font-family: "PoppinsBold";
            margin-top: 40px;
            margin-bottom: 4px;
            text-align: center;
            font-weight: bolder;
            font-size: 32px;
            letter-spacing: 1px;
            color: $btn-background;
        }
        
        &-subtitle {
            margin-top: 0px;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            text-align: center;
            letter-spacing: 1px;
            color: $input-text-color;
        }

        &-btn {
            margin-top: 60px;
        }
    }
}
